<template>
	<div>
		<div class="welcom">
			<div class="cn">欢迎来到军创中心</div>	
		</div>		
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},
	computed: {},
	created() {},
	mounted() {},
	methods: {
		handleChange(value) {
			console.log(`selected ${value}`);
		},
		handleBlur() {
			console.log('blur');
		},
		handleFocus() {
			console.log('focus');
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
		}
	}
};
</script>

<style lang="less">
.welcom {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 32px;
	height: calc(~"100vh - 300px");
}
</style>
